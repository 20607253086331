<template>
	<div>
		<a-modal :visible="visible" :title="title" :closable="false">
			<template slot="footer">
				<a-button key="back" @click="onCancel()"> Cancelar </a-button>
				<a-button key="submit" type="primary" @click="handleSubmit"> {{ submitLabel }} </a-button>
			</template>
			<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
				<a-form layout="vertical" :form="form" @submit="handleSubmit">
					<div class="row">
						<div class="col-md-12">
							<a-form-item label="Marca">
								<a-input
									v-decorator="[
										'name',
										{
											rules: [
												{
													required: true,
													message: 'Campo requerido.',
												},
											],
										},
									]"
									autocomplete="off"
								/>
							</a-form-item>
						</div>
					</div>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>
<script>
//
import Swal from 'sweetalert2'
import utilities from '@/services/utilities'

export default {
	name: 'insertOrUpdateMotorcycleBrand',
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.motorcycleBrand.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.motorcycleBrand.spinnerLoader
		},
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		},
		payload: {
			type: Object,
			default: function () {
				return {}
			},
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			submitLabel: '',
		}
	},
	methods: {
		onCancel() {
			this.$emit('closeModal')
			this.form.resetFields()
		},
		handleSubmit(e) {
			// console.log('handleSubmit')
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
					}
					if (utilities.objectValidate(this.payload, 'id', false)) {
						payload.id = this.payload.id
						this.$store.dispatch('motorcycleBrand/UPDATE_BRAND', { payload }).then((response) => {
							this.onCancel()
						})
					} else {
						this.$store.dispatch('motorcycleBrand/CREATE_BRAND', { payload }).then((response) => {
							this.onCancel()
						})
					}
				}
			})
		},
	},
	watch: {
		payload(newValue) {
			setTimeout(() => {
				if (utilities.objectValidate(newValue, 'id', false)) {
					this.form.setFieldsValue({
						name: newValue.name,
					})
					this.submitLabel = 'Actualizar'
				} else {
					this.submitLabel = 'Guardar'
				}
			}, 100)
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>